@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --text-color-light: #fbfbfb;

    /* --primary-color: #121915; */
    --primary-color: #0E4945;
    --secondary-color: #333939;
    --tertiary-color: #74644B;
    --info-color: #7DB799;

    --bg-light-color: #EEEBE9;
    --bg-dark-color: #0E4945;

    --border-radius: 0.375rem;

    --screen-bg-color: #F9F8F8;
}

body {
    margin: 0;
    font-family: 'Inter', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--primary-color);
    background-color: #FFFFFF;
    /* background-color: #f0f0f0; */
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
    transition: 0.4s;
    color: unset;
}

.navbar-brand {
    margin-top: -5px;
}

.container-divider {
    background-color: var(--bg-dark-color);
    text-align: center;
    padding: 1rem;
    font-size: 1.5rem;
    color: var(--text-color-light)
}

.container-divider-model {
    background-color: #f7f4ee;
    text-align: center;
    color: var(--primary-color);
    padding: 1rem;
    font-size: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--primary-color);
    font-weight: 700;
}

.footerContainer {
    background-color: var(--bg-light-color);
    color: #121915B2;
    font-size: 14px;
}

.footerContainer hr {
    border: 1px solid #A1A1A1;
    margin: 2rem 0;
}

.footerFlex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    text-align: center;
}

.footerContactInfoContainer {
    font-weight: 400;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
    flex-wrap: wrap;
}

.footerContactInfoContainer div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.footerCopyrightContainer {
    text-align: center;
}

.footerSocialMediaContainer {
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    margin: 2rem 0;
}

.footerPrivacyContainer {
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
}

.navbar {
    background-color: var(--bg-light-color);
}

.navbar .dropdown-menu.show {
    border: unset;
    -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.2);
}

.nav-link.active,
.nav-item.dropdown.active {
    font-weight: bold;
}

.modalGallery .modal-content {
    background-color: rgba(0, 0, 0, 0.8);
}

.modalGallery .modal-header {
    border-bottom: 0;
}

.modalGallery .modal-header button {
    color: var(--text-color-light) !important;
    font-size: 1.5rem;
}

.scheduleCallModal .modal-header {
    border: none;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1200;
}

.itemHeader {
    font-size: 26px;
    margin-bottom: 2rem;
}

.modelHeader {
    font-size: 1.5rem;
    color: var(--bg-dark-color)
}

.modelSubHeader {
    font-size: 1rem;
    font-weight: 500;
    color: var(--secondary-color);
    display: flex;
    gap: 2px;
}

.modelSummaryContainer svg {
    min-width: 35px;
    margin-top: 4px;
}

h1 + hr,
h2 + hr {
    border: 4px solid var(--info-color);
    border-radius: 4px;
    width: 100px;
    margin: 25px auto;
    opacity: 1;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    width: 40px !important;
    height: 40px !important;
}

.pinCodeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.pinCode {
    font-size: 2rem;
    height: 4rem;
    width: 4rem;
    outline: none;
    text-align: center;
    border-radius: .5rem;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.flexContainer h3.itemHeader,
.flexContainer h2.itemHeader {
    margin: 1rem 0;
}

.carousel-indicators [data-bs-target] {
    width: 30px;
    height: 6px;
    border-radius: 15%;
}

h1 {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--primary-color);
}

h1 span {
    color: var(--info-color);
}

h2 {
    color: var(--primary-color);
    font-size: 1.75rem;
}

h3 {
    color: var(--tertiary-color);
    font-size: 1.5rem;
}

h4 {
    color: #74644B;
    font-size: 18px;
    font-weight: 300;
}

h4 + p,
h4 + a {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 18px;
}

h5 {
    font-size: 18px;
    font-weight: 500;
}

p {
    color: var(--secondary-color);
    font-size: 1.125rem;
}

.btn-primary,
.btn-outline-primary {
    background-color: #0E4945;
    border-color: var(--primary-color) !important;
    font-weight: 500;
    font-size: 1rem;
    /* padding: 4px 18px; */
}

.btn-outline-primary {
    background-color: #fff;
    color: var(--primary-color) !important;
}

.btn-primary:disabled {
    background-color: #CCCCCC !important;
    border-color: #EEEBE9 !important;
}

.btn-primary:hover,
.btn-primary:active,
.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--info-color) !important;
    border-color: var(--info-color) !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
    background-color: #0E4945 !important;
    border-color: var(--primary-color) !important;
    color: #fff !important;

}

.btn-outline-primary:hover svg,
.btn-outline-primary:active svg {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(257deg) brightness(100%) contrast(101%);

}

.screenContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 4rem 0;
}

.screenContainer .flexContainer {
    align-items: center;
}

.introContainer > .flexContainer {
    gap: 5rem;
}

.benefitsContainer {
    background-color: var(--screen-bg-color);
    text-align: center;
}

.teamContainer {
    text-align: center;
    margin-top: 5rem;
}

.benefitsContainer span,
.exhibitionContainer span,
.teamContainer span {
    color: var(--info-color);
}

.exhibitionContainer img {
    -webkit-box-shadow: 0 0 41px 0 #c1caceb3;
    box-shadow: 0 0 41px 0 #c1caceb3;
}

.benefitsContainer h2 + p,
.teamContainer h2 + p {
    font-size: 20px;
    font-weight: 400;
}

.benefitsContainer h3 {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 500;
}

.benefitsContainer h3 + p {
    color: var(--secondary-color);
    font-size: 16px;
    font-weight: 400;
}

.svgContainer {
    border-radius: 50%;
    background-color: #D2F0E1;
    border: 0.5rem solid #E8FFF3;
    width: 60px;
    height: 60px;
    position: relative;
    margin: 1rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.howItWorksContainer h3 {
    color: var(--primary-color);
    font-size: 22px;
    font-weight: 600;
}

.howItWorksContainer p {
    font-size: 1rem;
}

.howItWorksContainer .flexContainer {
    align-items: center;
}

.stepNumber {
    color: #EEEBE9;
    font-size: 3rem;
    position: absolute;
    top: -40px;
    left: -65px;
    font-weight: 900;
}

ul {
    padding: 0;
    position: relative;
    font-weight: 500;
}

.standard-features-container li {
    padding: 0.1rem;
    margin-left: 2rem;
    font-weight: 300;
    color: var(--secondary-color);
}

.cart-item-info-container li {
    font-weight: 300;
}

::marker {
    font-weight: 600;
    color: var(--info-color);
    font-size: 1.1rem;
}

.infoContainer {
    margin: 4rem auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.overlappedImagesContainer {
    align-items: center;
    position: relative;
}

.overlappedImagesContainer .mainImage {
    max-width: 100%;
}

.overlappedImagesContainer .secondaryImage {
    display: none;
}

.infoDarkContainer {
    background-color: var(--bg-dark-color);
}

.infoDarkContainer h2,
.infoDarkContainer h3,
.infoDarkContainer p {
    color: #FFFFFF;
}

.carousel-caption {
    position: absolute;
    text-align: left;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    right: unset;
    bottom: unset;
    padding: 1rem;
    font-weight: 300;
    color: #FFFFFF;
    background: linear-gradient(150deg, rgba(0, 0, 0, 0.2) 34%, rgba(207, 207, 207, 0.1) 100%);
    letter-spacing: 4px;
    cursor: default;
    display: flex;
    flex-direction: column;
    padding-left: 3rem;
    padding-bottom: 3rem;
}

.carousel-caption span:first-child {
    font-size: 1.37rem;
    margin-top: auto;
}

.carousel-caption span:nth-child(2) {
    font-size: 1rem;
}

.carousel-control-prev,
.carousel-indicators {
    z-index: 950;
}

.btn-outline {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.btn-outline:hover,
.btn-outline:active,
.btn-outline:focus {
    background-color: var(--info-color) !important;
    border-color: var(--info-color) !important;
    color: #FFFFFF !important;
}

.vcard {
    color: #FFFFFF;
}

.vcard .card-header {
    background: rgb(42, 85, 79);
    background: linear-gradient(39deg, rgba(42, 85, 79, 1) 0%, rgba(138, 127, 112, 1) 100%);
}

.vcardInfoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding-left: 1rem;
}

.pressContainer {
    background-color: #EFF8F3;
    padding: 5rem 0 4rem 0;
}

.pressContainer .card {
    border: none;
    max-width: 90%;
    margin: 0 auto;
}

.pressContainer .card-header {
    width: 100%;
    text-align: center;
    min-height: 135px;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border: none;
}

.pressContainer .card-body {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.pressContainer .card-header img {
    max-width: 45%;
    min-width: 45%;
    margin: 0 auto;
}

.pressContainer .card-body button {
    width: 100%;
}

.pressContainer .react-multi-carousel-list {
    padding-bottom: 5rem;
}

.pressContainer .react-multiple-carousel__arrow {
    background: unset;
    bottom: -8px;
}

.pressContainer .react-multiple-carousel__arrow::before {
    color: var(--primary-color);
}

.pressContainer .react-multiple-carousel__arrow--left {
    left: calc(5% + 1px);
}

.pressContainer .react-multiple-carousel__arrow--right {
    right: calc(5% + 1px);
}

.pressContainer .react-multi-carousel-dot--active button {
    background: var(--primary-color);
}

.pressContainer .react-multi-carousel-track li {
    padding: 0;
    border-radius: 10px;
}

.pressContainer .react-multi-carousel-item {
    margin-left: 0;
}

.pressContainer .react-multi-carousel-dot {
    margin-left: 1rem;
}

.gallery-thumbnail {
    position: absolute;
    width: calc(100% - calc(var(--bs-gutter-x)));
    background: rgba(20, 24, 31, 0.3);
    color: #FFFFFF;
    padding: 0.5rem 0.75rem;
    backdrop-filter: blur(2px);
    bottom: 0;
    cursor: pointer;
}

.ourStoryContainer {
    text-align: center;
    padding-top: 2rem;
}

.ourStoryContainer h1,
.ourStoryContainer p {
    color: unset;
}

.ourStoryContainer img {
    max-width: 100%;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    height: 80%;
}

.product-container .section-title {
    color: #121915;
    font-size: 16px;
    font-weight: 500;
}

.product-info-container h1 {
    color: #114A46;
    font-size: 24px;
    font-weight: 500;
}

.product-info-container p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 57, 57, 0.8);
}

.product-info-container .product-price {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.02em;
}

.option-container h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.pakville-option-value-container {
    background-color: #FFFFFF;
    border: 1px solid rgba(198, 198, 198, 1);
    padding: 1rem 0.75rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.pakville-option-disabled {
    background-color: rgba(249, 248, 248, 1);
    border-color: #EEEBE9;
    opacity: .65;
    cursor: not-allowed;
}

.pakville-option-selected {
    background-color: #F6FFFA;
    border: 1px solid var(--info-color);
}

.product-container .pakville-option-value-container h4 {
    font-size: 16px;
    font-weight: 600;
    color: rgba(17, 74, 70, 1);
}

.product-container .pakville-option-value-container p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: rgba(73, 81, 81, 1);
}

.product-summary-container .quantity-input,
.cart-container .quantity-input {
    border: none;
    padding: 0;
    min-width: 50px;
    max-width: 50px;
    text-align: center;
}

.product-summary-container .data-info {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.product-checkout-note-container,
.product-checkout-note-container button {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: rgba(51, 57, 57, 0.8);
}

.product-add-to-cart-container svg {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(257deg) brightness(100%) contrast(101%);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.nav-cart {
    position: relative;
}

.nav-cart .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 0.65rem;
}

.recent-item-backdrop {
    opacity: 0 !important;
}

.cart-container h1 > span {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: rgba(161, 161, 161, 1);
}

.cart-list-header {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(161, 161, 161, 1);
}

.cart-list-header > div:first-child {
    color: rgba(51, 57, 57, 1);
}

.cart-list-body .cart-item {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.cart-list-body p {
    font-size: 14px;
    color: rgba(125, 125, 125, 1);
}

.cart-list-body .cart-item-info .cart-item-name,
.cart-list-body .cart-item-total {
    font-weight: 600;
    color: rgba(51, 57, 57, 1);
}

.btn-link {
    color: rgba(161, 161, 161, 1);
}

.btn-link:hover,
.btn-link:active {
    color: rgb(121, 121, 121);
}

.image-gallery-icon:hover {
    color: rgba(125, 183, 153, 1) !important;
}

.product-gallery-original img {
    border: 1px solid #C6C6C6 !important;
    border-radius: var(--bs-border-radius) !important;
    min-height: 500px !important;
    max-height: 500px !important;
}

.product-gallery-thumbnail {
    border: 2px solid #C6C6C6;
    border-radius: var(--bs-border-radius) !important;
    opacity: 0.8;
    margin-right: 10px;
    transition: 0.8s;
    min-height: 94px !important;
}

.product-gallery-thumbnail img {
    border-radius: var(--bs-border-radius) !important;
}

.product-gallery-thumbnail.active,
.product-gallery-thumbnail:focus {
    border: 2px solid rgba(125, 183, 153, 1);
    border-radius: var(--bs-border-radius) !important;
    opacity: 1;
}

.product-gallery-thumbnail:hover {
    border: 2px solid rgba(125, 183, 153, 0.51);
    border-radius: var(--bs-border-radius) !important;
    opacity: 1;
}

.image-gallery-thumbnails-wrapper {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.checkout-container {
    background-color: rgba(249, 248, 248, 1);
    min-height: calc(100vh - 105px);
}

.checkout-container .card {
    margin-bottom: 1rem;
    border: none;
}

.checkout-container .form-label {
    font-size: 15px;
}

.store-info-modal-details svg {
    margin-right: 1rem;
    width: 1.2rem;
    height: 1.2rem;
}

.checkout-container .accordion-button:not(.collapsed) {
    background-color: unset;
    box-shadow: none
}

.leaflet-attribution-flag {
    opacity: 0 !important;
}

.blogs-container {
    min-height: 20vh;
}

.blogs-container h2 {
    font-size: 16px;
    color: #114A46;
}

.technologyContent {
    gap: 8px;
}

.technologyContent h2 {
    max-width: 100%;
}

.author-container .author-avatar {
    background-color: #f8f8f8;
    width: 35px;
    height: 35px;
    position: relative;
    border-radius: 50%;
}

.author-container .author-avatar img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    padding: 5px;
}

.blog-container h1 {
    font-size: 2.5rem;
    color: #114A46;
    margin-bottom: 2rem;
}

.blog-container h2 {
    font-size: 1.5rem;
    color: var(--secondary-color);
}

.blog-container h3 {
    color: var(--secondary-color);
}

.blog-container ul {
    padding-left: 2rem;
    font-weight: normal;
}

.blog-container section a {
    color: var(--info-color);
}

.pakvilleReasonContainer {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    text-align: start;
    padding: 1.5rem;
}

.pakvilleReasonContainer h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--info-color);
    margin: 0;
}

.pakvilleReasonContainer p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
}

.pakvilleReasonContainer p span:first-child {
    font-size: 32px;
    font-weight: 600;
}

.pakvilleReasonContainer p span:nth-child(2) {
    /*font-weight: 600;*/
}

.galleryContainer .nav-tabs .nav-link {
    color: #121915;
    font-size: 18px;
    font-weight: 400;
}

.galleryContainer .nav-tabs .nav-link:hover,
.galleryContainer .nav-link:focus-visible {
    border-color: transparent;
}

.galleryContainer .nav-tabs .nav-link.active {
    color: #114A46;
    border: unset;
    border: 4px solid var(--info-color);
    border-radius: var(--border-radius);
}

.galleryContainer .accordion-button:not(.collapsed) {
    background: unset;
    box-shadow: none;
}

.galleryContainer .accordion-button {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #121915;
}

.galleryContainer .accordion-button::after {
    margin-left: unset;
    margin-right: 8px;
    order: -1;
}

.galleryContainer .accordion-item {
    border: none;
}

.galleryContainer .tab-pane.active.show {
    min-height: 40vh;
    position: relative;
}

.project-container ul {
    padding: 0;
    list-style: none;
    font-weight: 400;
    margin: 0;
}

.project-info-container {
    padding: 24px;
    border-radius: 8px;
    background: var(--screen-bg-color);

}

@media (min-width: 576px) {
    .product-gallery-original img {
        min-height: 630px !important;
        max-height: 630px !important;
    }
}

@media (min-width: 768px) {

    .product-gallery-original img {
        min-height: 480px !important;
        max-height: 480px !important;
    }

    .product-gallery-thumbnail {
        min-height: 120px !important;
    }

    .benefitsContainer {
        background-image: url("../public/images/home/benefits_pattern.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .ourStoryContainerBg {
        background: url("../public/images/story/pattern.png"), rgb(2, 2, 1);
        background: url("../public/images/story/pattern.png"), linear-gradient(193deg, rgba(2, 2, 1, 1) 48%, rgba(14, 73, 69, 1) 89%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        min-height: calc(60vh - 105px);
        position: absolute;
        z-index: -1200;
        top: 60px;
    }

    .ourStoryContainer h1,
    .ourStoryContainer p {
        color: #fff;
    }

    .carousel-caption {
        padding-bottom: 5rem;
    }

    .carousel-caption span:first-child {
        font-size: 2rem;
        margin-top: auto;
    }

    .carousel-caption span:nth-child(2) {
        font-size: 1.5rem;
    }

    .btn-primary {
        font-size: 18px;
        padding: 8px 28px;
    }

    .pressContainer .react-multiple-carousel__arrow--left {
        left: calc(35% + 1px);
    }

    .pressContainer .react-multiple-carousel__arrow--right {
        right: calc(35% + 1px);
    }

    .pressContainer .card-header {
        min-width: 25%;
        max-width: 150px;
        min-height: 250px;
        height: 100%;
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        border-top-right-radius: 0;
    }

    .pressContainer .card-header img {
        max-width: 100%;
        min-width: 100%;
    }

    .pressContainer .card-body {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        border-bottom-left-radius: 0;
    }

    .pressContainer .card-body button {
        width: unset;
    }


    .pressContainer .react-multi-carousel-item {
        margin-left: 1.5rem;
    }

}

@media (min-width: 992px) {

    .product-gallery-original img {
        min-height: 550px !important;
        max-height: 550px !important;
    }

    .nav-cart .badge {
        position: absolute;
        top: 0;
        right: -3px;
        font-size: 0.72rem;
    }

    h1 {
        font-size: 2.25rem;
    }

    h2 {
        font-size: 2.25rem;
    }

    h3 {
        font-size: 1.25rem;
    }

    p {
        font-size: 1rem;
    }

    .carousel-indicators {
        left: unset;
        right: 0;
        margin-right: 10%;
    }

    .introContainer > .flexContainer {
        gap: 2rem;
    }

    .pakvertContainer .flexContainer {
        text-align: left;
        align-items: start;
    }

    .introContent,
    .installationContent {
        align-items: flex-start !important;
        text-align: left;
        padding-left: 2rem;
    }

    .exhibitionContainer .svgContainer {
        margin: unset;
        margin-bottom: 1rem;
    }

    .howItWorksContainer .flexContainer {
        align-items: flex-start;
        text-align: left;
    }

    .stepNumber {
        top: -45px;
        left: -20px;
    }

    .footerCopyrightContainer {
        text-align: left;
    }

    .footerSocialMediaContainer {
        justify-content: center;
        margin: 0;
    }

    .footerPrivacyContainer {
        justify-content: end;
    }

    .modelSubHeader {
        font-size: 1.2rem;
    }

    .overlappedImagesContainer .secondaryImage {
        display: block;
    }

    .carousel-caption {
        padding-left: 5rem;
        padding-bottom: 5rem;
    }

    .carousel-caption span:first-child {
        font-size: 3.5rem;
        margin-top: auto;
    }

    .carousel-caption span:nth-child(2) {
        font-size: 2.1rem;
    }

    .recent-item-modal .modal-dialog {
        position: absolute;
        right: 22px;
        top: 26px;
    }

    .related-blog-img-container {
        max-width: 25%;
    }

    .pakvilleReasonContainer {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
    }

    .footerFlex {
        flex-direction: row;
    }

    .footerContactInfoContainer {
        justify-content: start;
    }


    .technologyContent h2 {
        max-width: 25%;
    }
}

@media (min-width: 992px) and (max-width: 1100px) {
    .navbar > .container {
        max-width: unset
    }

    .navbar > .container a {
        font-size: 0.71rem;
    }
}

@media (min-width: 1100px) and (max-width: 1199px) {
    .navbar > .container {
        max-width: unset
    }

    .navbar > .container a {
        font-size: 0.8rem;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .navbar > .container {
        max-width: unset
    }

    .navbar > .container a {
        font-size: 0.9rem;
    }
}


@media (min-width: 1200px) {

    .product-gallery-original img {
        min-height: 643px !important;
        max-height: 643px !important;
    }

    .ourStoryContainerBg {
        min-height: calc(75vh - 105px);
    }


    .overlappedImagesContainer {
        align-items: flex-end;
    }

    .overlappedImagesContainer .secondaryImage {
        display: block;
        max-width: 100%;
        position: absolute;
        left: -35px;
    }

    .overlappedImagesContainer .mainImage {
        max-width: 80%;
    }

    .pressContainer .react-multiple-carousel__arrow--left {
        left: calc(40% + 1px);
    }

    .pressContainer .react-multiple-carousel__arrow--right {
        right: calc(40% + 1px);
    }

    .carousel-caption {
        padding-left: 5rem;
        padding-bottom: 7rem;
    }

    .carousel-caption span:first-child {
        font-size: 4.37rem;
        margin-top: auto;
    }

    .carousel-caption span:nth-child(2) {
        font-size: 2.625rem;
    }

}